var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$hasPermissions(_vm.clientSession, ['ANNUAL_BOLI_REVIEW'], 1))?_c('div',[_c('vue-headful',{attrs:{"title":_vm.pageTitle + ' - ' + _vm.idToPeriod()}}),_c('div',{staticClass:"has-text-centered has-background-primary",staticStyle:{"margin-bottom":"20px"}},[_c('h1',{staticClass:"is-size-6 has-text-white",staticStyle:{"padding":"5px 0px"}},[_vm._v(" "+_vm._s(_vm.pageheading.toLocaleUpperCase() + " - " + _vm.idToPeriod())+" ")])]),_c('div',{staticStyle:{"max-width":"95%","margin":"auto"}},[(_vm.isDownloading || _vm.isRegenerating)?_c('div',{attrs:{"id":"body-content-area"}},[_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"center","align-items":"center","flex-direction":"column"}},[_c('h1',{staticClass:"title is-4"},[_vm._v(" "+_vm._s(_vm.isDownloading ? "Downloading" : "Regenerating")+" Report"+_vm._s(_vm.selectedRows.length > 1 || _vm.selectedRows.length === 0 ? "s" : "")+". This may take a moment. ")]),_c('p',{staticStyle:{"margin-top":"-1rem"}},[_vm._v(" Please do not close this window or navigate away from this page. ")])]),_c('Loading')],1):_c('div',{attrs:{"id":"body-content-area"}},[_c('div',{class:{ hidden: !_vm.isLoading || !_vm.isDownloading }},[_c('spin-loader',{staticClass:"center-it"})],1),_c('div',{class:{ visible: _vm.isLoading && !_vm.isDownloading }},[_c('div',{staticClass:"top-bar-spacer",staticStyle:{"height":"8px","width":"100%"}},[_vm._v("   ")]),_c('div',[_c('div',{staticClass:"control grid-button top-bar",staticStyle:{"display":"flex","justify-content":"flex-end","margin-bottom":"1rem"}},[_c('div',{attrs:{"id":"buttongroup"}},[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"field is-grouped"},[(
                          _vm.$hasPermissions(
                            _vm.clientSession,
                            ['ANNUAL_QUESTIONNAIRE'],
                            1
                          )
                        )?_c('div',{staticClass:"control"},[_c('button',{staticClass:"button is-accent has-text-white",staticStyle:{"margin-right":".9rem"},attrs:{"disabled":true || _vm.selectedRows.length === 0},on:{"click":_vm.downloadSelected}},[_c('span',[_vm._v(" Download "+_vm._s(_vm.selectedRows.length > 1 || _vm.selectedRows.length === 0 ? "Selected" : "BOLI Reviews")+" ")])]),_c('button',{staticClass:"button is-accent has-text-white",attrs:{"disabled":_vm.reports.filter(function (report) { return report.selected; }).length === 0},on:{"click":_vm.regenerateSelected}},[_c('span',[_vm._v(" Regenerate "+_vm._s(_vm.reports.filter(function (report) { return report.selected; }).length > 1 || _vm.reports.filter(function (report) { return report.selected; }).length === 0 ? "Selected" : "BOLI Reviews")+" ")])])]):_vm._e(),(
                          _vm.$hasPermissions(
                            _vm.clientSession,
                            ['ANNUAL_QUESTIONNAIRE'],
                            2
                          )
                        )?_c('div',{staticClass:"control"},[_c('button',{staticClass:"button is-dark",attrs:{"disabled":_vm.selectedRows.length === 0},on:{"click":_vm.openDeleteModal}},[_c('span',[_vm._v(" Delete ")])])]):_vm._e(),_c('div',{staticClass:"control"},[_c('button',{staticClass:"button is-light",attrs:{"disabled":_vm.isLoadingAssetReports},on:{"click":_vm.getAssetReports}},[_c('span',{staticClass:"icon"},[(!_vm.isLoadingAssetReports)?_c('i',{staticClass:"fas fa-sync-alt"}):_c('i',{staticClass:"fas fa-spinner fa-spin"})])])])])])])])])]),_c('div',[_c('div',{ref:"divWrapper"},[(_vm.maxHeight > 0)?_c('Grid',{ref:"reportGrid",staticClass:"boli-review-table",style:({ height: _vm.maxHeight + 'px' }),attrs:{"id":"reportGrid","filter":_vm.filter,"data-items":_vm.sort.length > 0
                    ? _vm.sort[0].dir === 'desc'
                      ? _vm.lodash
                          .sortBy(_vm.institutionReports, _vm.sort[0].field)
                          .reverse()
                      : _vm.lodash.sortBy(_vm.institutionReports, _vm.sort[0].field)
                    : _vm.institutionReports,"selected-field":_vm.selectedField,"sortable":true,"sort":_vm.sort,"filterable":true,"pageable":Object.assign({}, _vm.pageable,
                  {pageSizes: _vm.pageSizes}),"page-size":_vm.pageSize,"skip":_vm.pagination.skip,"take":_vm.pagination.take,"total":_vm.pagination.total,"columns":_vm.columns},on:{"pagechange":_vm.pageChangeHandler,"sortchange":_vm.sortChangeHandler,"filterchange":_vm.filterChangeHandler,"selectionchange":_vm.onSelectionChange,"headerselectionchange":_vm.onHeaderSelectionChange},scopedSlots:_vm._u([{key:"downloadTemplate",fn:function(ref){
                var props = ref.props;
return [_c('td',{staticClass:"has-text-centered"},[(false)?_c('a',{staticClass:"download",attrs:{"disabled":true},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                          name: 'AnnualBOLIReportConfigPage',
                          params: {
                            reportId: props.dataItem.BOLIReviewId,
                            id: 0,
                          },
                        })}}},[_c('i',{staticClass:"fas fa-cog"})]):_vm._e(),_c('a',{staticClass:"download",attrs:{"disabled":true},on:{"click":function (ev) { return _vm.download(props.dataItem.BOLIReviewId, ''); }}},[_c('i',{staticClass:"fas fa-download"})]),_vm._v("   "),_c('a',{staticClass:"link",attrs:{"href":"javascript:void()"},on:{"click":function($event){$event.preventDefault();return _vm.regenerate(props.dataItem)}}},[_c('i',{staticClass:"fas fa-redo"})])])]}},{key:"statementDateTemplate",fn:function(ref){
                        var props = ref.props;
return [_c('td',[(props.dataItem.completionDate)?_c('span',[_vm._v(_vm._s(_vm.formatDateNoTZ( props.dataItem.completionDate, "MM/DD/YYYY" )))]):_c('span',[_vm._v(_vm._s(props.dataItem.completionDate))])])]}},{key:"boardMemo",fn:function(ref){
                        var props = ref.props;
return [_c('td',{staticClass:"has-text-centered"},[_c('a',{staticClass:"download",attrs:{"disabled":_vm.getLatestStatus('BoardMemo', props.dataItem) !== 'Completed'},on:{"click":function (ev) { return _vm.download(props.dataItem, 'BoardMemo', 'docx'); }}},[_c('i',{staticClass:"fas fa-download"})]),_vm._v("   "),_c('a',{staticClass:"link",attrs:{"disabled":_vm.getLatestStatus('BoardMemo', props.dataItem) !== 'Completed',"href":"javascript:void()"},on:{"click":function($event){$event.preventDefault();_vm.$router.push({
                          name: 'AnnualBOLIBatchReportPreviewPage',
                          params: {
                            id: _vm.moment(props.dataItem.monthEndDate).format(
                              'YYYY'
                            ),
                            reportId: props.dataItem.id,
                          },
                          query: {
                            isViewOnly: true,
                            reportType: 'BoardMemo',
                          },
                        })}}},[_c('i',{staticClass:"fas fa-eye"})])])]}},{key:"execSummary",fn:function(ref){
                        var props = ref.props;
return [_c('td',{staticClass:"has-text-centered"},[_c('a',{staticClass:"download",attrs:{"disabled":_vm.getLatestStatus('ExecSummary', props.dataItem) !== 'Completed'},on:{"click":function (ev) { return _vm.download(props.dataItem, 'ExecutiveSummary'); }}},[_c('i',{staticClass:"fas fa-download"})]),_vm._v("   "),_c('a',{staticClass:"download",attrs:{"disabled":true || _vm.getLatestStatus('ExecSummary', props.dataItem) !== 'Completed'},on:{"click":function (ev) { return _vm.viewExec(props.dataItem.questionnaireId); }}},[_c('i',{staticClass:"fas fa-eye"})])])]}},{key:"appendix",fn:function(ref){
                        var props = ref.props;
return [_c('td',{staticClass:"has-text-centered"},[_c('a',{staticClass:"download",attrs:{"disabled":_vm.getLatestStatus('Appendix', props.dataItem) !== 'Completed'},on:{"click":function (ev) { return _vm.download(props.dataItem, 'Appendix'); }}},[_c('i',{staticClass:"fas fa-download"})]),_vm._v("   "),_c('a',{staticClass:"link",attrs:{"disabled":_vm.getLatestStatus('Appendix', props.dataItem) !== 'Completed',"href":"javascript:void()"},on:{"click":function($event){$event.preventDefault();_vm.$router.push({
                          name: 'AnnualBOLIBatchReportPreviewPage',
                          params: {
                            id: _vm.moment(props.dataItem.monthEndDate).format(
                              'YYYY'
                            ),
                            reportId: props.dataItem.id,
                          },
                          query: {
                            isViewOnly: true,
                            reportType: 'Appendix',
                          },
                        })}}},[_c('i',{staticClass:"fas fa-eye"})])])]}},{key:"creationDateTemplate",fn:function(ref){
                        var props = ref.props;
return [_c('td',{staticClass:"has-text-centered"},[_c('span',[_vm._v(_vm._s(_vm.formatDateNoTZ(props.dataItem.createdAt, "MM/DD/YYYY")))])])]}},{key:"batchNameCell",fn:function(ref){
                        var props = ref.props;
return [_c('td',[_c('span',[_vm._v(" "+_vm._s(props.dataItem.displayName)+" ")])])]}},{key:"reportStatus",fn:function(ref){
                        var props = ref.props;
return [_c('td',{staticClass:"editable",staticStyle:{"white-space":"unset!important","overflow":"visible!important","width":"100px!important","max-width":"100px!important","height":"auto!important","max-height":"100px!important"}},[(props.dataItem.status === '3')?_c('span',[_vm._v(" All Completed ")]):_c('span',{staticStyle:{"white-space":"unset!important","overflow":"visible!important","width":"300px!important","max-width":"100%!important","height":"auto!important","max-height":"100px!important","display":"flex"}},[_c('table',[_c('tr',[_c('td',{staticStyle:{"width":"0px!important","display":"none","border":"none!important"}}),_c('td',{staticStyle:{"border":"none!important"}},[_vm._v(_vm._s(_vm.getLatestStatus('BoardMemo', props.dataItem)))]),_c('td',[_vm._v(_vm._s(_vm.getLatestStatus('ExecSummary', props.dataItem)))]),_c('td',[_vm._v(_vm._s(_vm.getLatestStatus('Appendix', props.dataItem)))])])])])])]}},{key:"headerCell",fn:function(ref){
                        var props = ref.props;
return [_c('td',{staticStyle:{"cursor":"pointer !important","background-color":"#fafafa !important"},on:{"click":function($event){$event.preventDefault();return (function () {
                        if (props.dir === undefined) { props.dir = 'asc'; }
                        else if (props.dir === 'asc') { props.dir = 'desc'; }
                        else { props.dir = undefined; }

                        _vm.sortChangeHandler({
                          sort: [
                            {
                              field: props.field,
                              dir: props.dir,
                            } ],
                        });
                      }).apply(null, arguments)}}},[_c('a',{attrs:{"href":"javascript:void()"}},[(
                          props.dir === 'asc' &&
                          _vm.sort.find(function (_rec) { return _rec.field === 'batchName'; })
                        )?_c('span',{staticClass:"k-icon k-i-sort-asc-sm"}):_vm._e(),(
                          props.dir === 'desc' &&
                          _vm.sort.find(function (_rec) { return _rec.field === 'batchName'; })
                        )?_c('span',{staticClass:"k-icon k-i-sort-desc-sm"}):_vm._e(),_c('span',{staticStyle:{"color":"black"}},[_vm._v(_vm._s(props.title))])])])]}},{key:"reportTypeFilterSlotTemplate",fn:function(ref){
                        var props = ref.props;
                        var methods = ref.methods;
return [_c('div',{staticClass:"k-filtercell"},[_c('div',{staticClass:"k-filtercell-wrapper"},[_c('input',{staticClass:"k-textbox",staticStyle:{"height":"30px"},attrs:{"type":"text","placeholder":"Search Institution Name","id":("" + (props.field))},domProps:{"value":props.value != '' ? props.value : null},on:{"input":function (ev) {
                            methods.change({
                              operator: 'contains',
                              field: props.field,
                              value: ev.target.value,
                              syntheticEvent: ev,
                            });
                          }}})])])]}},{key:"statusFilterSlotTemplate",fn:function(ref){
                          var props = ref.props;
                          var methods = ref.methods;
return [_c('table',{staticStyle:{"border":"none!important","padding":"0!important","margin":"auto 0!important","margin-top":"3px!important"}},[_c('tr',{staticStyle:{"padding":"0!important","vertical-align":"center"}},[_c('th',{staticStyle:{"vertical-align":"center","width":"0px","border":"none!important","padding":"0!important"}}),_c('th',{staticStyle:{"vertical-align":"center","border":"none!important","border-right":"1px solid rgba(0, 0, 0, .1)!important","text-align":"left"}},[_vm._v("Memo")]),_c('th',{staticStyle:{"vertical-align":"center","border":"none!important","border-right":"1px solid rgba(0, 0, 0, .1)!important","text-align":"left"}},[_vm._v("Summary")]),_c('th',{staticStyle:{"vertical-align":"center","border":"none!important","text-align":"left"}},[_vm._v("Appendix")])])])]}},{key:"boardMemoFilter",fn:function(ref){
                          var props = ref.props;
                          var methods = ref.methods;
return [_c('table',{staticStyle:{"border":"none!important","padding":"0!important","margin":"auto 0!important","margin-top":"3px!important"}},[_c('tr',{staticStyle:{"padding":"0!important","vertical-align":"center"}},[_c('th',{staticStyle:{"vertical-align":"center","width":"0px","border":"none!important","padding":"0!important"}}),_c('th',{staticStyle:{"vertical-align":"center","border":"none!important","text-align":"center"}},[_vm._v("Memo")])])])]}},{key:"execSummaryFilter",fn:function(ref){
                          var props = ref.props;
                          var methods = ref.methods;
return [_c('table',{staticStyle:{"border":"none!important","padding":"0!important","margin":"auto 0!important","margin-top":"3px!important"}},[_c('tr',{staticStyle:{"padding":"0!important","vertical-align":"center"}},[_c('th',{staticStyle:{"vertical-align":"center","width":"0px","border":"none!important","padding":"0!important"}}),_c('th',{staticStyle:{"vertical-align":"center","border":"none!important","text-align":"center","white-space":"pre","word-wrap":"break-word","word-break":"keep-all"}},[_vm._v("Summary")])])])]}},{key:"appendixFilter",fn:function(ref){
                          var props = ref.props;
                          var methods = ref.methods;
return [_c('table',{staticStyle:{"border":"none!important","padding":"0!important","margin":"auto 0!important","margin-top":"3px!important"}},[_c('tr',{staticStyle:{"padding":"0!important","vertical-align":"center"}},[_c('th',{staticStyle:{"vertical-align":"center","width":"0px","border":"none!important","padding":"0!important"}}),_c('th',{staticStyle:{"vertical-align":"center","border":"none!important","text-align":"center"}},[_vm._v("Appendix")])])])]}},{key:"creationDateFilterCellTemplate",fn:function(ref){
                          var props = ref.props;
                          var methods = ref.methods;
return [_c('div',{staticClass:"k-filtercell"},[_c('div',{staticClass:"k-filtercell-wrapper"},[_c('input',{staticClass:"k-textbox",attrs:{"type":"text","placeholder":"yyyy-mm-dd","id":("" + (props.field))},domProps:{"value":props.value != '' ? props.value : null},on:{"input":function (ev) {
                            methods.change({
                              operator: 'contains',
                              field: props.field,
                              value: ev.target.value,
                              syntheticEvent: ev,
                            });
                          }}})])])]}}],null,false,1727747935)}):_vm._e()],1)]),(_vm.showDeleteModal)?_c('div',{staticClass:"modal is-active",attrs:{"id":"delete-modal"}},[_c('div',{staticClass:"modal-background",on:{"click":function($event){_vm.showDeleteModal = false}}}),_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head has-bg-danger"},[_c('div',{staticClass:"modal-card-title has-bg-danger"},[_vm._v(" Confirm Delete ")]),_c('a',{staticClass:"delete",attrs:{"aria-label":"close"},on:{"click":function($event){_vm.showDeleteModal = false}}})]),_c('section',{staticClass:"modal-card-body"},[_vm._m(0),_c('div',{staticClass:"field"},[_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"focus",rawName:"v-focus"},{name:"model",rawName:"v-model.trim",value:(_vm.confirmationText),expression:"confirmationText",modifiers:{"trim":true}}],staticClass:"input",attrs:{"type":"text","placeholder":"type `delete` and then click confirm"},domProps:{"value":(_vm.confirmationText)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.deleteIfConfirmed.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.confirmationText=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})])])]),_c('footer',{staticClass:"modal-card-foot"},[_c('a',{staticClass:"button is-danger",attrs:{"disabled":_vm.confirmationText !== 'delete'},on:{"click":_vm.deleteIfConfirmed}},[_vm._m(1),_c('span',[_vm._v("Confirm")])]),_c('a',{staticClass:"button",on:{"click":function($event){_vm.showDeleteModal = false}}},[_vm._v("Cancel")])])])]):_vm._e()])])])],1):_c('div',[_c('vue-headful',{attrs:{"title":_vm.pageTitle}}),_vm._m(2),_c('center',[_c('b',[_vm._v("You Lack The Permissions Required To View This Page")])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_vm._v(" Type \""),_c('b',[_vm._v("delete")]),_vm._v("\" to confirm that you want to delete one or more items. ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fal fa-trash-alt"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"has-text-centered has-background-primary",staticStyle:{"margin-bottom":"20px"}},[_c('h1',{staticClass:"is-size-6 has-text-white",staticStyle:{"padding":"5px 0px"}},[_vm._v(" No Permission ")])])}]

export { render, staticRenderFns }