<template>
  <div>
    <div v-if="$hasPermissions(clientSession, ['ANNUAL_BOLI_REVIEW'], 1)">
      <vue-headful :title="pageTitle + ' - ' + idToPeriod()" />
      <div
        class="has-text-centered has-background-primary"
        style="margin-bottom: 20px"
      >
        <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">
          {{ pageheading.toLocaleUpperCase() + " - " + idToPeriod() }}
        </h1>
      </div>
      <div style="max-width: 95%; margin: auto">
        <div v-if="isDownloading || isRegenerating" id="body-content-area">
          <div
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            "
          >
            <h1 class="title is-4">
              {{ isDownloading ? "Downloading" : "Regenerating" }} Report{{
                selectedRows.length > 1 || selectedRows.length === 0 ? "s" : ""
              }}. This may take a moment.
            </h1>
            <p style="margin-top: -1rem">
              Please do not close this window or navigate away from this page.
            </p>
          </div>
          <Loading />
        </div>
        <div v-else id="body-content-area">
          <div :class="{ hidden: !isLoading || !isDownloading }">
            <spin-loader class="center-it" />
          </div>
          <div :class="{ visible: isLoading && !isDownloading }">
            <div class="top-bar-spacer" style="height: 8px; width: 100%">
              &nbsp;
            </div>

            <div>
              <div
                style="
                  display: flex;
                  justify-content: flex-end;
                  margin-bottom: 1rem;
                "
                class="control grid-button top-bar"
              >
                <div id="buttongroup">
                  <div class="level">
                    <div class="level-left">
                      <div class="field is-grouped">
                        <div
                          class="control"
                          v-if="
                            $hasPermissions(
                              clientSession,
                              ['ANNUAL_QUESTIONNAIRE'],
                              1
                            )
                          "
                        >
                          <button
                            class="button is-accent has-text-white"
                            style="margin-right: .9rem;"
                            :disabled="true || selectedRows.length === 0"
                            @click="downloadSelected"
                          >
                            <span>
                              Download
                              {{
                                selectedRows.length > 1 ||
                                selectedRows.length === 0
                                  ? "Selected"
                                  : "BOLI Reviews"
                              }}
                            </span>
                          </button>

                          <button
                            class="button is-accent has-text-white"
                            :disabled="reports.filter((report) => report.selected).length === 0"
                            @click="regenerateSelected"
                          >
                            <span>
                              Regenerate
                              {{
                                reports.filter((report) => report.selected).length > 1 ||
                                reports.filter((report) => report.selected).length === 0
                                  ? "Selected"
                                  : "BOLI Reviews"
                              }}
                            </span>
                          </button>
                        </div>
                        <div
                          class="control"
                          v-if="
                            $hasPermissions(
                              clientSession,
                              ['ANNUAL_QUESTIONNAIRE'],
                              2
                            )
                          "
                        >
                          <button
                            class="button is-dark"
                            :disabled="selectedRows.length === 0"
                            @click="openDeleteModal"
                          >
                            <span> Delete </span>
                          </button>
                        </div>
                        <div class="control">
                          <button
                            :disabled="isLoadingAssetReports"
                            class="button is-light"
                            @click="getAssetReports"
                          >
                            <span class="icon">
                              <i
                                v-if="!isLoadingAssetReports"
                                class="fas fa-sync-alt"
                              ></i>
                              <i v-else class="fas fa-spinner fa-spin"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div ref="divWrapper">
                <Grid
                  v-if="maxHeight > 0"
                  ref="reportGrid"
                  id="reportGrid"
                  class="boli-review-table"
                  :style="{ height: maxHeight + 'px' }"
                  :filter="filter"
                  :data-items="
                    sort.length > 0
                      ? sort[0].dir === 'desc'
                        ? lodash
                            .sortBy(institutionReports, sort[0].field)
                            .reverse()
                        : lodash.sortBy(institutionReports, sort[0].field)
                      : institutionReports
                  "
                  :selected-field="selectedField"
                  :sortable="true"
                  :sort="sort"
                  :filterable="true"
                  :pageable="{
                    ...pageable,
                    pageSizes: pageSizes,
                  }"
                  :page-size="pageSize"
                  :skip="pagination.skip"
                  :take="pagination.take"
                  :total="pagination.total"
                  :columns="columns"
                  @pagechange="pageChangeHandler"
                  @sortchange="sortChangeHandler"
                  @filterchange="filterChangeHandler"
                  @selectionchange="onSelectionChange"
                  @headerselectionchange="onHeaderSelectionChange"
                >
                  <template v-slot:downloadTemplate="{ props }">
                    <td class="has-text-centered">
                      <a
                      v-if="false"
                        :disabled="true"
                        class="download"
                        @click.prevent="
                          $router.push({
                            name: 'AnnualBOLIReportConfigPage',
                            params: {
                              reportId: props.dataItem.BOLIReviewId,
                              id: 0,
                            },
                          })
                        "
                      >
                        <i class="fas fa-cog"></i>
                      </a>
                      <a
                      :disabled="true"
                        class="download"
                        @click="
                          (ev) => download(props.dataItem.BOLIReviewId, '')
                        "
                      >
                        <i class="fas fa-download"></i>
                      </a>
                      &nbsp;
                      <a
                        class="link"
                        href="javascript:void()"
                        @click.prevent="regenerate(props.dataItem)"
                      >
                        <i class="fas fa-redo"></i>
                      </a>
                    </td>
                  </template>
                  <template v-slot:statementDateTemplate="{ props }">
                    <td>
                      <span v-if="props.dataItem.completionDate">{{
                        formatDateNoTZ(
                          props.dataItem.completionDate,
                          "MM/DD/YYYY"
                        )
                      }}</span>
                      <span v-else>{{ props.dataItem.completionDate }}</span>
                    </td>
                  </template>

                  <template v-slot:boardMemo="{ props }">
                    <td class="has-text-centered">
                      <a
                        class="download"
                        :disabled="
                          getLatestStatus('BoardMemo', props.dataItem) !== 'Completed'
                        "
                        @click="(ev) => download(props.dataItem, 'BoardMemo', 'docx')"
                      >
                        <i class="fas fa-download"></i>
                      </a>
                      &nbsp;
                      <a
                        :disabled="
                          getLatestStatus('BoardMemo', props.dataItem) !== 'Completed'
                        "
                        class="link"
                        href="javascript:void()"
                        @click.prevent="
                          $router.push({
                            name: 'AnnualBOLIBatchReportPreviewPage',
                            params: {
                              id: moment(props.dataItem.monthEndDate).format(
                                'YYYY'
                              ),
                              reportId: props.dataItem.id,
                            },
                            query: {
                              isViewOnly: true,
                              reportType: 'BoardMemo',
                            },
                          })
                        "
                      >
                        <i class="fas fa-eye"></i>
                      </a>
                    </td>
                  </template>

                  <template v-slot:execSummary="{ props }">
                    <td class="has-text-centered">
                      <a
                        class="download"
                        :disabled="
                          getLatestStatus('ExecSummary', props.dataItem) !== 'Completed'
                        "
                        @click="
                          (ev) => download(props.dataItem, 'ExecutiveSummary')
                        "
                      >
                        <i class="fas fa-download"></i>
                      </a>
                      &nbsp;
                      <a
                        class="download"
                        :disabled="true || getLatestStatus('ExecSummary', props.dataItem) !== 'Completed'
                        "
                        @click="
                          (ev) => viewExec(props.dataItem.questionnaireId)
                        "
                      >
                        <i class="fas fa-eye"></i>
                      </a>
                    </td>
                  </template>

                  <template v-slot:appendix="{ props }">
                    <td class="has-text-centered">
                      <a
                        class="download"
                        :disabled="
                          getLatestStatus('Appendix', props.dataItem) !== 'Completed'
                        "
                        @click="(ev) => download(props.dataItem, 'Appendix')"
                      >
                        <i class="fas fa-download"></i>
                      </a>
                      &nbsp;
                      <a
                        :disabled="
                          getLatestStatus('Appendix', props.dataItem) !== 'Completed'
                        "
                        class="link"
                        href="javascript:void()"
                        @click.prevent="
                          $router.push({
                            name: 'AnnualBOLIBatchReportPreviewPage',
                            params: {
                              id: moment(props.dataItem.monthEndDate).format(
                                'YYYY'
                              ),
                              reportId: props.dataItem.id,
                            },
                            query: {
                              isViewOnly: true,
                              reportType: 'Appendix',
                            },
                          })
                        "
                      >
                        <i class="fas fa-eye"></i>
                      </a>
                    </td>
                  </template>

                  <template v-slot:creationDateTemplate="{ props }">
                    <td class="has-text-centered">
                      <span>{{
                        formatDateNoTZ(props.dataItem.createdAt, "MM/DD/YYYY")
                      }}</span>
                    </td>
                  </template>
                  <template v-slot:batchNameCell="{ props }">
                    <td>
                      <span>
                        {{ props.dataItem.displayName }}
                      </span>
                    </td>
                  </template>

                  <template v-slot:reportStatus="{ props }">
                    <td class="editable" style="white-space:unset!important; overflow: visible!important; width: 100px!important; max-width: 100px!important; height: auto!important; max-height: 100px!important;">
                      <span v-if="props.dataItem.status === '3'">
                        All Completed
                      </span>
                      <span v-else style="white-space:unset!important; overflow: visible!important; width: 300px!important; max-width: 100%!important; height: auto!important; max-height: 100px!important; display: flex;">
                        <table>
                          <tr>
                            <td style="width: 0px!important; display: none; border: none!important;"></td>
                            <td style=" border: none!important;">{{ getLatestStatus('BoardMemo', props.dataItem) }}</td>
                            <td>{{ getLatestStatus('ExecSummary', props.dataItem) }}</td>
                            <td>{{ getLatestStatus('Appendix', props.dataItem) }}</td>
                          </tr>
                        </table>
                      </span>
                    </td>
                  </template>

                  <template v-slot:headerCell="{ props }">
                    <td
                      style="
                        cursor: pointer !important;
                        background-color: #fafafa !important;
                      "
                      @click.prevent="
                        () => {
                          if (props.dir === undefined) props.dir = 'asc';
                          else if (props.dir === 'asc') props.dir = 'desc';
                          else props.dir = undefined;

                          sortChangeHandler({
                            sort: [
                              {
                                field: props.field,
                                dir: props.dir,
                              },
                            ],
                          });
                        }
                      "
                    >
                      <a href="javascript:void()">
                        <span
                          v-if="
                            props.dir === 'asc' &&
                            sort.find((_rec) => _rec.field === 'batchName')
                          "
                          class="k-icon k-i-sort-asc-sm"
                        ></span>
                        <span
                          v-if="
                            props.dir === 'desc' &&
                            sort.find((_rec) => _rec.field === 'batchName')
                          "
                          class="k-icon k-i-sort-desc-sm"
                        ></span>
                        <span style="color: black">{{ props.title }}</span>
                      </a>
                    </td>
                  </template>
                  <template
                    v-slot:reportTypeFilterSlotTemplate="{ props, methods }"
                  >
                    <div class="k-filtercell">
                      <div class="k-filtercell-wrapper">
                        <input
                          type="text"
                          class="k-textbox"
                          style="height: 30px;"
                          placeholder="Search Institution Name"
                          :id="`${props.field}`"
                          :value="props.value != '' ? props.value : null"
                          @input="
                            (ev) => {
                              methods.change({
                                operator: 'contains',
                                field: props.field,
                                value: ev.target.value,
                                syntheticEvent: ev,
                              });
                            }
                          "
                        />
                      </div>
                    </div>
                  </template>

                  <template
                    v-slot:statusFilterSlotTemplate="{ props, methods }"
                  >
                    <table style="border: none!important;padding: 0!important; margin: auto 0!important; margin-top: 3px!important;">
                      <tr style="padding: 0!important; vertical-align: center;">
                        <th style=" vertical-align: center;width: 0px; border: none!important; padding: 0!important;"></th>
                        <th style=" vertical-align: center;border: none!important; border-right: 1px solid rgba(0, 0, 0, .1)!important; text-align: left;">Memo</th>
                        <th style=" vertical-align: center;border: none!important; border-right: 1px solid rgba(0, 0, 0, .1)!important;text-align: left;">Summary</th>
                        <th style=" vertical-align: center;border: none!important; text-align: left;">Appendix</th>
                      </tr>
                    </table>
                  </template>

                  <template
                    v-slot:boardMemoFilter="{ props, methods }"
                  >
                    <table style="border: none!important;padding: 0!important; margin: auto 0!important; margin-top: 3px!important;">
                      <tr style="padding: 0!important; vertical-align: center;">
                        <th style=" vertical-align: center;width: 0px; border: none!important; padding: 0!important;"></th>
                        <th style=" vertical-align: center;border: none!important; text-align: center;">Memo</th>
                      </tr>
                    </table>
                  </template>

                  <template
                    v-slot:execSummaryFilter="{ props, methods }"
                  >
                    <table style="border: none!important;padding: 0!important; margin: auto 0!important; margin-top: 3px!important;">
                      <tr style="padding: 0!important; vertical-align: center;">
                        <th style=" vertical-align: center;width: 0px; border: none!important; padding: 0!important;"></th>
                        <th style="vertical-align: center; border: none!important; text-align: center; white-space: pre; word-wrap: break-word; word-break: keep-all;">Summary</th>
                      </tr>
                    </table>
                  </template>

                  <template
                    v-slot:appendixFilter="{ props, methods }"
                  >
                    <table style="border: none!important;padding: 0!important; margin: auto 0!important; margin-top: 3px!important;">
                      <tr style="padding: 0!important; vertical-align: center;">
                        <th style=" vertical-align: center;width: 0px; border: none!important; padding: 0!important;"></th>
                        <th style="vertical-align: center;border: none!important; text-align: center;">Appendix</th>
                      </tr>
                    </table>
                  </template>

                  <template
                    v-slot:creationDateFilterCellTemplate="{ props, methods }"
                  >
                    <div class="k-filtercell">
                      <div class="k-filtercell-wrapper">
                        <input
                          type="text"
                          class="k-textbox"
                          placeholder="yyyy-mm-dd"
                          :id="`${props.field}`"
                          :value="props.value != '' ? props.value : null"
                          @input="
                            (ev) => {
                              methods.change({
                                operator: 'contains',
                                field: props.field,
                                value: ev.target.value,
                                syntheticEvent: ev,
                              });
                            }
                          "
                        />
                      </div>
                    </div>
                  </template>
                </Grid>
              </div>
            </div>

            <div
              id="delete-modal"
              class="modal is-active"
              v-if="showDeleteModal"
            >
              <div
                class="modal-background"
                @click="showDeleteModal = false"
              ></div>
              <div class="modal-card">
                <header class="modal-card-head has-bg-danger">
                  <div class="modal-card-title has-bg-danger">
                    Confirm Delete
                  </div>
                  <a
                    class="delete"
                    aria-label="close"
                    @click="showDeleteModal = false"
                  ></a>
                </header>
                <section class="modal-card-body">
                  <div class="content">
                    Type "<b>delete</b>" to confirm that you want to delete one
                    or more items.
                  </div>
                  <div class="field">
                    <div class="control">
                      <input
                        type="text"
                        class="input"
                        placeholder="type `delete` and then click confirm"
                        v-focus
                        v-model.trim="confirmationText"
                        @keydown.enter.prevent="deleteIfConfirmed"
                      />
                    </div>
                  </div>
                </section>
                <footer class="modal-card-foot">
                  <a
                    class="button is-danger"
                    @click="deleteIfConfirmed"
                    :disabled="confirmationText !== 'delete'"
                  >
                    <span class="icon">
                      <i class="fal fa-trash-alt"></i>
                    </span>
                    <span>Confirm</span>
                  </a>
                  <a class="button" @click="showDeleteModal = false">Cancel</a>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <vue-headful :title="pageTitle" />
      <div
        class="has-text-centered has-background-primary"
        style="margin-bottom: 20px"
      >
        <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">
          No Permission
        </h1>
      </div>

      <center>
        <b>You Lack The Permissions Required To View This Page</b>
      </center>
    </div>
  </div>
</template>

<script>
//* ***** this is grid native example (there is a wrapper version) *******
import Vue from 'vue'
import Loading from '../Loading'
import { mapState } from 'vuex'
import { activeSite } from '../../vuex-actions'
import { filterBy } from '@progress/kendo-data-query'
import { Grid } from '@progress/kendo-vue-grid'

import moment from 'moment'

import _ from 'lodash'

let pageName = 'Annual {{ coliOrBoli }} Review - Period Details'

// https://stackoverflow.com/a/1909508
function debounce (fn, ms) {
  let timer = 0
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}

const BOLIReviewSubReportStatusCodes = {
  NotStarted: 0,
  DataLoaded: 1,
  InProgress: 2,
  Completed: 3,
  Failed: 4
}

export default {
  components: {
    Loading,
    Grid: Grid
  },
  data () {
    return {
      BOLIReviewSubReportStatusCodes,
      lodash: _,
      isRegenerating: false,
      maxHeight: 0,
      isDownloading: false,
      minResults: 0,
      isLoadingAssetReports: false,
      dates: {
        start: {
          minDate: new Date(1969, 0, 1),
          maxDate: new Date(moment().toDate())
        },
        end: {
          minDate: new Date(moment().toDate()),
          maxDate: new Date(moment().toDate())
        },
        currentDateRange: {
          start: new Date(moment().year(), 0, 1),
          end: new Date(moment().toDate())
        }
      },
      reportDateRanges: [
        { text: 'Current Year', value: '1' },
        { text: 'Last Year', value: '2' },
        { text: 'Custom Date Range', value: '3' },
        { text: 'All', value: '4' }
      ],
      selectedReportDateRange: '1',
      props: {
        grid: Grid,
        field: String,
        filterType: String,
        value: [String, Number, Boolean, Date],
        operator: String
      },
      skip: 0,
      take: 20,
      pageSize: 20,
      page: 1,
      totalRecords: 0,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
      },
      payload: {
        filter: {
          allReports: false,
          institutionId: 0,
          dateFilter: {
            startDate: null,
            endDate: null
          }
        },
        limit: 20,
        offset: 0
      },
      input: {
        name: '',
        reportType: '',
        user: '',
        institution: '',
        startDate: '',
        endDate: ''
      },
      reports: [],
      confirmationText: '',
      managingReportId: null,
      showTypeSelectionModal: false,
      interval: null,
      intervalCount: 5,
      isLoading: true,
      showDeleteModal: false,
      selectedField: 'selected',
      selectedRows: [],
      sort: [{ field: 'name', dir: 'asc' }],
      filter: {
        logic: 'and',
        filters: []
      },
      staticColumns: [
        {
          field: 'id',
          title: 'Id',
          filterable: false,
          hidden: true
        },
        {
          field: 'reviewName',
          title: 'Institution',
          cell: 'batchNameCell',
          filterable: true,
          filterCell: 'reportTypeFilterSlotTemplate'
        },
        {
          title: 'Created Date',
          width: '110px',
          cell: 'creationDateTemplate',
          filterable: false
        },
        {
          title: '',
          width: '80px',
          cell: 'boardMemo',
          filterable: true,
          sortable: false,
          filterCell: 'boardMemoFilter'
        },
        {
          title: '',
          width: '100px',
          cell: 'execSummary',
          filterable: true,
          sortable: false,
          filterCell: 'execSummaryFilter'
        },
        {
          title: '',
          width: '100px',
          cell: 'appendix',
          filterable: true,
          sortable: false,
          filterCell: 'appendixFilter'
        },
        {
          field: 'status',
          title: 'Status',
          filterable: true,
          width: '300px',
          cell: 'reportStatus',
          filterCell: 'statusFilterSlotTemplate'
        },
        {
          field: 'path',
          title: 'Actions',
          filterable: false,
          sortable: false,
          width: '110px',
          cell: 'downloadTemplate'
        }
      ],
      pagination: {
        take: 10,
        skip: 0,
        total: 0
      }
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    coliOrBoli () {
      return this.clientSession.siteId === 1 ? 'BOLI' : 'COLI'
    },
    pageTitle () {
      return pageName.replaceAll('{{ coliOrBoli }}', this.coliOrBoli)
    },
    pageheading () {
      return pageName.replaceAll('{{ coliOrBoli }}', this.coliOrBoli)
    },
    hasSelection () {
      return this.selectedRows.length > 0
    },
    hasPluralSelection () {
      return this.selectedRows.length > 1
    },
    managingReport () {
      return this.reports.find(
        (report) => report.BOLIReviewId === this.managingReportId
      )
    },
    institutionReports: function () {
      if (this.reports.length === 0) {
        return []
      }

      let filtered = filterBy(
        this.reports.map((report) => {
          if (this.selectedRows.includes(report.BOLIReviewId)) {
            report.selected = true
          }

          return report
        }),
        this.filter
      )
      return filtered
    },
    areAllSelected () {
      let selected = this.institutionReports.findIndex(
        (item) => item.selected === false
      )
      let isUndefined = this.institutionReports.findIndex(
        (item) => item.selected === undefined
      )

      if (this.institutionReports.length === 0) return false
      return selected === -1 && isUndefined === -1
    },
    columns () {
      return [
        {
          field: 'selected',
          width: '28px',
          filterable: false,
          headerSelectionValue: this.areAllSelected
        },
        ...this.staticColumns
      ]
    },
    hideDateLabel () {
      return (
        this.selectedReportDateRange !== '3' &&
        this.selectedReportDateRange !== '4'
      )
    },
    pageSizes () {
      let sizes = [this.minResults]

      sizes.push(this.minResults)
      sizes.push(5)
      sizes.push(10)
      sizes.push(15)
      sizes.push(20)
      sizes.push(50)
      sizes.push(100)
      sizes.push('All')

      // remove dups
      sizes = [...new Set(sizes)]

      // return a sorted array from smallest to largest
      return sizes.sort((a, b) => a - b)
    }
  },
  watch: {
    managingReportId (curr, prev) {
      if (curr) this.showTypeSelectionModal = true
    },
    sort: {
      handler: async function (val, oldVal) {
        await this.getAssetReports()
      },
      deep: true
    }
  },
  mounted () {
    const fixTable = () => {
      let reportGrid = document.getElementById('reportGrid')
      if (reportGrid) {
        let header = reportGrid.querySelector('.k-grid-header')

        if (header) {
          let row = header.querySelector('tr') // get's the first row

          if (row) {
            // get th 4, 5, 6
            let ths = row.querySelectorAll('th')

            if (ths.length > 0) {
              ths[3].colSpan = 3
              ths[3].innerHTML = 'Documents'
              ths[3].style.textAlign = 'center'

              ths[4].remove()
              ths[5].remove()
              ths[6].style.textAlign = 'center'
            }
          }
        }
      } else {
        setTimeout(_ => fixTable(), 100)
      }
    }

    window.requestAnimationFrame(fixTable)
    this.__setup()
  },
  unmounted () {
    clearInterval(this.interval)
  },
  updated () {
    // Manually repopulate filter values after server-side querying
    if (this.filters) {
      this.filters.forEach((element) => {
        var filterInput = document.getElementById(element.field)
        if (filterInput) {
          filterInput.value = element.value
        }
      })
    }
  },
  methods: {
    downloadSelected () {
      // todo - download all files for each selected report
    },
    regenerateSelected () {
      let selected = this.reports.filter((report) => report.selected)

      let response = confirm(
        'Are you sure you want to regenerate ' + (selected.length > 1 ? 'these' : 'this') + ' report' + (selected.length > 1 ? 's' : '') + '?'
      )

      if (!response) return

      for (let report of selected) {
        report.createdAt = new Date()
      }

      this.isRegenerating = true

      this.api().annualBOLIReview.queueReportsToGenerate(
        {
          period: (selected.map((report) => report.statementDate))[0],
          institutions: selected.map((report) => report.institutionId),
          isRegenerating: 1
        },
        () => {
          this.selectedRows = []
          this.getAssetReports()
          this.isRegenerating = false
        }
      )
    },
    updateStatus (status) {
      window.requestAnimationFrame((_) => {
        this.axios
          .post('annual-boli-review/set-status/' + status, this.selectedRows)
          .then((response) => {
            this.selectedRows = []
            this.getAssetReports()
          })
          .catch((_) => {
            // console.log(error)
          })
      })
    },
    getStatus (status) {
      return status === 1 ? 'Sent' : status === 2 ? 'Returned' : 'Unsent'
    },
    reset (event, value) {
      value.showSaveDialog = false
      event.target.blur()
      this.getAssetReports()
    },
    async submitChanges (original, type, newValue) {
      let originalValue = this.reports.filter(
        (_document) => _document.BOLIReviewId === original.BOLIReviewId
      )

      if (originalValue.length > 0) {
        let response = await this.axios.put(
          '8925-reports/' +
            this.$route.params.BOLIReviewId +
            '/reports/' +
            original.BOLIReviewId,
          original
        )

        if (response.status >= 200 && response.status < 300) {
          this.getAssetReports()
        }
      }

      this.$forceUpdate()
    },
    async download (questionnaire, reportType, fileExtension = 'pdf') {
      this.isLoading = true
      try {
        const response = await this.axios.get(
          'annual-boli-review/' +
            questionnaire.id +
            '/download?reportIds=' +
            questionnaire.id +
            '&reportType=' +
            reportType +
            '&format=' +
            fileExtension,
          { responseType: 'blob' }
        )

        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: 'application/pdf' })

        // Create a temporary anchor element
        const a = document.createElement('a')

        // Use URL.createObjectURL to get a URL for the blob
        a.href = URL.createObjectURL(blob)
        a.download =
          response.headers['content-disposition'].split('filename=')[1]

        // Trigger a click event on the anchor to start the download
        a.click()

        // Cleanup: release the blob URL
        URL.revokeObjectURL(a.href)
      } catch (error) {}
      this.isLoading = false
    },
    regenerate (report) {
      // console.log(report)
      let response = confirm(
        'Are you sure you want to regenerate this report?'
      )

      if (!response) return

      report.createdAt = new Date()

      this.api().annualBOLIReview.queueReportsToGenerate(
        {
          period: report.statementDate,
          institutions: [report.institutionId],
          isRegenerating: 1
        },
        () => {
          this.getAssetReports()
        }
      )
    },
    formatDate (date) {
      if (moment.utc(date).isValid()) {
        return moment.utc(date).local().format('MM/DD/YYYY')
      }
    },
    viewReport (report) {
      this.$router.push({
        name: 'ViewReport',
        params: { id: report.BOLIReviewId, report: report }
      })
    },
    deleteIfConfirmed (event, data) {
      if (this.confirmationText !== 'delete') return

      // Do Delete Action in event loop
      // This will free up the UI and reload the data after each successfully deletes.
      // This provides a better user experience.
      this.reports
        .filter((x) => x.selected)
        .forEach((row) => {
          this.api().annualBOLIReview.deleteReport(
            { batchId: 0, reportId: row.id },
            () => this.getAssetReports()
          )
        })

      this.getAssetReports()
      this.confirmationText = ''
      this.showDeleteModal = false

      this.selectedRows = []
    },
    getLatestStatus (column, report) {
      if (report && report.reportStatusDetailed) {
        const allCompleted = report.reportStatusDetailed !== '' && Object.values(report.reportStatusDetailed).every(
          (subReport) => subReport.Status === BOLIReviewSubReportStatusCodes.Completed
        )

        let statusText = ''

        if (allCompleted) {
          let keys = Object.keys(report.reportStatusDetailed)

          statusText = keys && keys.length === 0 ? 'Queued' : 'Completed'
        } else if (
          report.reportStatusDetailed[column + 'Report']
        ) {
          let status = report.reportStatusDetailed[column + 'Report'].Status

          switch (status) {
            case BOLIReviewSubReportStatusCodes.Completed:
              statusText = 'Completed'
              break
            case BOLIReviewSubReportStatusCodes.Failed:
              statusText = 'Failed'
              break
            case BOLIReviewSubReportStatusCodes.InProgress:
              statusText = 'In Progress'
              break
            default:
              statusText = 'Not Started'
              break
          }
        } else {
          return statusText
        }

        return statusText
      }

      return 'Not Started'
    },
    onHeaderSelectionChange (event) {
      let checked = event.event.target.checked
      Vue.set(
        this,
        'reports',
        this.reports.map((item) => {
          return { ...item, selected: checked }
        })
      )

      if (checked) {
        this.selectedRows = this.reports
          .filter(function (e) {
            return e.selected === true
          })
          .map(function (i) {
            return i.questionnaireId
          })
      } else {
        this.selectedRows = []
      }
    },
    openDeleteModal () {
      this.showDeleteModal = true
    },
    onSelectionChange (event) {
      let checked = event.event.target.checked
      const id = event.dataItem.id

      if (checked) {
        this.selectedRows.push(id)
      } else {
        var idx = this.selectedRows.indexOf(id)
        if (idx > -1) {
          this.selectedRows.splice(idx, 1)
        }
      }

      event.dataItem.selected = checked
    },
    stripDate: function (dateString) {
      // 2016-02-24T00:11:00Z to 2016-02-24
      // Note this is needed to prevent off-by-one date errors due to the timestamp and timezone offset
      return dateString.substring(0, 10)
    },
    pageChangeHandler: function (event) {
      this.pagination.skip = event.page.skip
      this.pagination.take = isNaN(event.page.take) ? 1000 : event.page.take
      this.perPage = isNaN(event.page.take) ? 1000 : event.page.take
      this.pageSize = isNaN(event.page.take) ? 1000 : event.page.take

      localStorage.setItem('reportPageSize', event.page.take)
      this.getAssetReports()
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort
      localStorage.setItem('reportSort', JSON.stringify(this.sort))
      this.getAssetReports()
    },
    filterChangeHandler: debounce(function (e) {
      this.filters = [
        {
          field: e.event.field,
          operator: 'contains',
          value: e.event.value
        }
      ]

      localStorage.setItem('batchreportFilters', JSON.stringify(this.filters))
      this.skip = 0
      this.getAssetReports(false)
    }, 500),
    clearFilters: function () {
      // Manually clear filter values
      if (this.filters) {
        this.filters.forEach((element) => {
          var filterInput = document.getElementById(element.field)
          if (filterInput) {
            filterInput.value = ''
          }
        })

        this.filters = []
      }

      this.pagination.skip = 0
      this.sort = [{ field: 'name', dir: 'DESC' }]

      localStorage.removeItem('batchreportFilters')
      localStorage.removeItem('reportSort')

      this.getAssetReports(false)
    },
    onStartDateRangeChange: function (e) {
      // Do nothing
    },
    onEndDateRangeChange: function (e) {
      // Do nothing
    },
    onReportDateRangeSelect: async function (e) {
      // Do nothing
    },
    async bulkDownload () {
      this.selectedRows.forEach(async (row) => {
        this.isDownloading = true
        await this.download(row, '')
      })

      setTimeout(() => {
        this.isDownloading = false
      }, 2000)
    },
    getAssetReports () {
      this.isLoadingAssetReports = true
      let batchNameFilter = this.filters
        ? this.filters.find((x) => x.field === 'reviewName')
        : {}
      let batchDateFilter = this.filters
        ? this.filters.find((x) => x.field === 'status')
        : {}

      this.api().annualBOLIReview.listAssetReportsInBatch(
        {
          take: this.pagination.take,
          skip: this.pagination.skip,
          sort: this.sort.length > 0 ? this.sort[0].field : 'name',
          desc: this.sort.length > 0 ? this.sort[0].dir : 'asc',
          reviewName:
            batchNameFilter !== undefined ? batchNameFilter.value : '',
          batchId: this.$route.params.id,
          status: batchDateFilter ? batchDateFilter.value : ''
        },
        (error, result) => {
          if (error) {
            this.handleApiErr(error)
          } else {
            this.reports = result.records.map((report) => {
              report.selected = false
              report.showSaveDialog = false
              report.reportStatusDetailed = JSON.parse(
                report.reportStatusDetailed ? report.reportStatusDetailed : '{}'
              )
              return report
            })
            this.pagination.total = result.pagination.totalRecords
            if (this.$refs.reportGrid !== null && this.$refs.reportGrid !== undefined && this.$refs.reportGrid.$el !== null && this.$refs.reportGrid.$el !== undefined) {
              this.$refs.reportGrid.$el
                .querySelectorAll('[type="checkbox"]')
                .forEach((checkbox) => {
                  checkbox.checked = false
                })
            }
            this.$forceUpdate()
          }

          this.isLoading = false
          this.isLoadingAssetReports = false
        }
      )
    },
    setupReportDisplay () {
      let that = this
      this.$nextTick(() => {
        let elements = [
          '.has-text-centered.has-background-primary',
          '.top-bar',
          '.top-bar-spacer',
          '.bottom-bar',
          '.bottom-bar-spacer'
        ]

        let func = async (results) => {
          let [numberOfResults, maxHeight] = results

          if (
            this.minResults &&
            this.maxHeight &&
            (numberOfResults === null || maxHeight === null)
          ) {
            return
          }

          this.pagination.take = numberOfResults
          this.pagination.perPage = numberOfResults
          this.pagination.minResults = numberOfResults
          this.maxHeight = maxHeight

          this.getAssetReports()
        }

        that.getListHeights(
          'body-content-area',
          40,
          79,
          elements,
          func.bind(this)
        )

        window.addEventListener('resize', () => {
          that.getListHeights(
            'body-content-area',
            40,
            79,
            elements,
            func.bind(that)
          )
        })
      })
    },
    async consumeLocalStorage () {
      if (localStorage.reportSkip) {
        this.skip = JSON.parse(localStorage.getItem('reportSkip'))
        localStorage.removeItem('reportSkip')
      }

      // if (localStorage.reportFilters) { this.filters = JSON.parse(localStorage.getItem('reportFilters')) }

      // if (localStorage.reportSort) { this.sort = JSON.parse(localStorage.getItem('reportSort')) }

      // if (localStorage.reportSelection) { await this.loadQueryParams(JSON.parse(localStorage.getItem('reportSelection'))) }

      if (localStorage.reportPageSize) {
        let pageSize = JSON.parse(localStorage.getItem('reportPageSize'))
        this.pagination.take = pageSize
        this.pagination.perPage = pageSize
        this.pagination.pageSize = pageSize
      }
    },
    __setup () {
      this.consumeLocalStorage()
      this.setupReportDisplay()
      this.getAssetReports()

      // this.interval = setInterval(() => {
      //   this.intervalCount--
      //   if (this.intervalCount === 0) {
      //     this.getAssetReports()
      //     this.intervalCount = 5
      //   }
      // }, 1000)
    },
    unmounted () {
      clearInterval(this.interval)
    }
  }
}
</script>

<style scoped>
.link {
  cursor: pointer;
  color: #7fb942;
}

.link:hover {
  color: #e69138;
}

.k-link {
  color: #000 !important;
}
</style>

<style>
.k-dropdown {
  width: auto !important;
  min-width: 200px;
}

#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}
.k-widget k-dateinput k-state-default k-label {
  font-weight: 600 !important;
  padding: 8px;
}
.k-checkbox:checked {
  background-color: #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-pager-numbers .k-state-selected {
  background-color: #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-grid th {
  font-weight: 600 !important;
}
.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  background-color: #cbe3b3;
  opacity: 0.8;
  font-weight: 600;
}
.k-list .k-item.k-state-selected {
  background-color: #7fb942;
}
.k-list .k-item.k-state-selected:hover {
  background-color: #cbe3b3;
  color: #0d0c0c;
}
.k-date-picker {
  width: 90%;
  margin-right: 6px;
  border: pink;
}
a.download,
a.bcc-color {
  color: #7fb942 !important;
}
.page-subtitle {
  color: #7fb942;
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 8px;
}
.reportDateStatus,
.status {
  margin-top: 10px;
}
.k-invalid-msg {
  display: inline-block;
}
.invalid {
  color: red;
}
.valid {
  color: green;
}
.bcc-color {
  color: #7fb942;
}
.grid-button .button {
  margin-bottom: 5px;
}
.k-grid td:first-child {
  color: transparent !important;
}

.k-grid th:first-child {
  color: transparent !important;
}

.hidden {
  display: none;
}

.visible {
  visibility: none;
}

#select-type-modal {
  z-index: 1000 !important;
}

.k-master-row > td:first-child {
  color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popover-contain {
  padding: 0.25rem;

  z-index: 50;
}

.editable:hover {
  -webkit-box-shadow: 0px 0px 4px 1px #aeaeae;
  box-shadow: 0px 0px 4px 1px #aeaeae;
  cursor: pointer;
}

.editable button {
  visibility: hidden;
  background: white;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  color: #7fb841;
  padding: 5px;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.saving {
  background: #d9d9d9 !important;
}

.editable input {
  width: 100%;
}

.editable select {
  width: 100%;
}

.editable input:focus ~ button {
  visibility: visible;
}

select {
  appearance: none;
  padding: 5px;
  border: none;
  font-family: inherit;
  outline: none;
}

.editable input,
.editable select {
  background-color: transparent;
  border: 0px;
  cursor: pointer;
}

.tooltip .tooltip-inner {
  background: white !important;
  color: #656565;
  padding: 0rem;

  border-radius: 0.25rem;
}

.tooltip {
  background: white !important;
  padding: 0rem;

  border-radius: 0.25rem;
  border: 1px solid #e2e2e2;
}

.v-popover .trigger {
  width: 100%;
}

tr {
  white-space: unset!important;
}
</style>

<style scoped></style>
